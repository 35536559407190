import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import mutationTypes from './mutationTypes';

const loadTenants = async ({ commit, dispatch }) => {
  try {
    const tenants = await api.getTenants();
    commit(mutationTypes.SET_TENANTS, tenants);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const loadTenant = async ({ commit, dispatch }, tenantUuid) => {
  try {
    const tenant = await api.getTenant(tenantUuid);
    commit(mutationTypes.SET_CURRENT_TENANT, tenant);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const updateTenant = async ({ dispatch }, tenant) => {
  try {
    await api.updateTenant(tenant);
    dispatch('handleSuccess', 'Tenant successfully updated!');
  } catch (err) {
    dispatch('handleError', err);
  }
};

const addNotification = ({ commit, dispatch }, notificationData) => {
  const DEFAULT_NOTIFICATION_TIMEOUT = 5000;
  const notification = {
    ...notificationData,
    uuid: uuidv4(),
    timeout: notificationData.timeout || DEFAULT_NOTIFICATION_TIMEOUT,
  };

  setTimeout(() => {
    dispatch('removeNotification', notification);
  }, notification.timeout);

  commit(mutationTypes.ADD_NOTIFICATION, notification);
};

const removeNotification = ({ commit }, notification) => {
  commit(mutationTypes.REMOVE_NOTIFICATION, notification);
};

const handleError = ({ dispatch }, error) => {
  const message = error.response && (error.response.data && error.response.data.message);

  const notificationData = {
    type: 'error',
    message: message || 'Something went wrong!',
  };

  dispatch('addNotification', notificationData);
};

const handleSuccess = ({ dispatch }, message) => {
  const notificationData = {
    type: 'success',
    message: message || 'Success!',
  };

  dispatch('addNotification', notificationData);
};

const setUser = ({ commit }, data) => {
  commit(mutationTypes.SET_USER, data);
};

export default {
  loadTenants,
  loadTenant,
  updateTenant,
  addNotification,
  removeNotification,
  handleSuccess,
  handleError,
  setUser,
};
