const getSearchParam = (searchParamName) => {
  if (!window.location.search) return null;

  const params = {};

  window.location.search.substring(1).split('&').forEach((paramKeyValue) => {
    const [key, value] = paramKeyValue.split('=');

    params[key] = value;
  });

  return params[searchParamName];
};

const isIntegration = () => {
  const { ancestorOrigins } = window.location;
  const { self, top } = window;

  return (ancestorOrigins && !!ancestorOrigins[0])
    || ((self && top) && (self !== top))
    || (getSearchParam('mode') === 'integration');
};

export default isIntegration;
