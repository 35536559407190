import http from './http';

const getTenants = () => http
  .get('/api/v1/client/tenants.json')
  .then((res) => res.data);

const getTenant = (uuid) => http
  .get(`/api/v1/client/tenants/${uuid}.json`)
  .then((res) => res.data);

const updateTenant = (tenant) => http
  .put(`/api/v1/client/tenants/${tenant.uuid}.json`, tenant)
  .then((res) => res.data);

export default {
  getTenants,
  getTenant,
  updateTenant,
};
