import { localStorage } from '@/utility';
import mutationTypes from './mutationTypes';

export default {
  [mutationTypes.ADD_NOTIFICATION](state, notification) {
    state.notifications = state.notifications.concat(notification);
  },
  [mutationTypes.REMOVE_NOTIFICATION](state, notification) {
    state.notifications = state.notifications.filter((n) => n.uuid !== notification.uuid);
  },
  [mutationTypes.SET_TENANTS](state, tenants) {
    state.tenants = tenants.results;
  },
  [mutationTypes.SET_CURRENT_TENANT](state, tenant) {
    state.tenant = tenant;
  },
  [mutationTypes.SET_USER](state, payload) {
    state.user = payload;
    localStorage.setItem('user', JSON.stringify(payload));
  },
};
