import GatewaySlave from '@nsoft/seven-gravity-gateway/slave';

let instance = null;
const allowedOrigins = process.env.VUE_APP_INTEGRATION_ALLOWED_ORIGINS;
const slaveId = process.env.VUE_APP_INTEGRATION_ID;

export default {
  /**
   * Initialize gateway.
   * @returns {Promise.<{*}>} - Returns master data if any
   */
  init() {
    const gatewayConfig = {
      allowedOrigins,
      slaveId,
      eventPropagation: {
        click: true,
      },
    };

    return new Promise((resolve) => {
      instance = GatewaySlave({
        load: (message) => {
          resolve(message);
        },
        ...gatewayConfig,
      });
    });
  },
  get api() {
    return instance;
  },
  sendMessage(message) {
    if (!this.api) return;

    this.api.sendMessage(message);
  },
};
