import { localStorage } from '@/utility';

const user = JSON.parse(localStorage.getItem('user'));

const authInterceptor = (requestConfig) => {
  const transformedConfig = requestConfig;

  transformedConfig.headers.Authorization = user.token;

  return transformedConfig;
};

// eslint-disable-next-line
export { authInterceptor };
