import Vue from 'vue';
import errorTracker from './plugins/errorTracker';
import App from './App';
import router from './router';
import store from './store';
import integrator from './plugins/seven-gravity-gateway/slave';
import { isIntegration } from './utility';
import vuetify from './plugins/vuetify';

// Initialize error tracker at the earliest app point
if (process.env.VUE_APP_ERROR_TRACKER_ACTIVE !== 'true') {
  errorTracker.init(Vue);
}

Vue.config.productionTip = false;

const bootstrapApp = (data) => {
  const user = {
    token: data.user && data.user.auth.token,
  };

  // Set user info
  store.dispatch('setUser', user);

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
};

if (isIntegration()) {
  integrator.init().then((message) => bootstrapApp(message.data));
} else {
  bootstrapApp({});
}
