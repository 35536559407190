import axios from 'axios';
import { authInterceptor } from './interceptors';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 5000,
});

http.interceptors.request.use(
  authInterceptor,
  (error) => Promise.reject(error),
);

export default http;
