<template>
  <v-app>
    <v-snackbar v-for="notification in notifications"
                :value="notification"
                :timeout="notification.timeout"
                :key="notification.uuid"
                :color="notification.type"
                bottom
                middle>
      {{ notification.message }}
      <v-btn color="white"
             text
             @click="$store.dispatch('removeNotification', notification)">
        Close
      </v-btn>
    </v-snackbar>
    <app-navigation />
    <v-content>
      <transition name="fade"
                  mode="out-in">
        <router-view />
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import integrator from './plugins/seven-gravity-gateway/slave';
import AppNavigation from './components/AppNavigation';

export default {
  name: 'App',
  components: {
    AppNavigation,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['notifications']),
  },
  created() {
    this.$router.replace('/');
  },
  mounted() {
    // Send event to parent app
    integrator.sendMessage({
      action: 'Slave.Loaded',
    });
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
